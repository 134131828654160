<template>
    <div class="customers container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        Customers
                        <div class="sub header">Manage customer profiles here</div>
                    </h3>
                </div>
                <div class="right aligned column">
                    <button class="ui black small button" type="button" @click="addConsultation"><i class="fontello-plus icon"></i> New Consultation</button>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Customers
                                        <div class="sub header">Manage customer profiles here</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="plus icon link" @click="addConsultation">
                                        <span>&plus;</span>
                                    </a>
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="ID, name, birth date(YYYY-MM-DD), contact no..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="ID, name, birth date(YYYY-MM-DD), contact no..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="name">Name</div>
                                <div class="item" data-value="id_no">ID No.</div>
                                <div class="item" data-value="dob">Date of Birth</div>
                                <div class="item" data-value="contact_no">Contact No.</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <table class="ui very basic unstackable responsive table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>ID No.</th>
                            <th>Date of Birth</th>
                            <th>Contact No.</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="customers.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="customer in customers" :key="`customer_tr_${customer.id}`">
                                <td data-title="Name">
                                    <router-link :to="{
                                        name: 'Customer',
                                        params: {
                                            id: customer.id
                                        }
                                    }">
                                        <div><strong>{{customer.name}}</strong></div>
                                    </router-link>
                                </td>
                                <td data-title="ID No.">{{customer.id_no}}</td>
                                <td data-title="D.O.B.">{{customer.dob|formatdate("DD MMM YYYY")}}</td>
                                <td data-title="Contact No."><a :href="`tel:${customer.contact_no}`">{{customer.contact_no}}</a></td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu">
                                        <div class="ui right dropdown fitted item">
                                            <i class="ellipsis vertical teal icon"></i>
                                            <div class="menu">
                                                <router-link class="item" :to="{
                                                    name: 'Customer',
                                                    params: {
                                                        id: customer.id
                                                    }
                                                }">Detail</router-link>
                                                <a class="item" href="javascript:void(0);" @click="editCustomer(customer)">Edit Info</a>
                                                <a class="item" href="javascript:void(0);" @click="editConsultation(customer)">Edit Consultation</a>
                                                <a class="item" href="javascript:void(0);" @click="addPrescription(customer)">New Treatment Prescription</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="5">No customer found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="customers.length">
                        <tr>
                            <th colspan="5" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div ref="consultationmodal" class="ui consultation modal">
            <div class="content">
                <consultation-form ref="consultationform" @next="resizeConsultationModal" @prev="resizeConsultationModal" @submitted="consultationSaved" @cancel="consultationCancelled" :key="`consultationform_${consultationform_key}`"/>
            </div>
        </div>

        <div ref="editcustomermodal" class="ui hidden modal">
            <div class="content">
                <personal-info-form ref="editcustomerform" :id="`editpersonalinfoform${_uid}`" :formdata="editcustomerformdata" @submit="updateCustomer" :key="`editpersonalinfoform_${editpersonalinfoform_key}`"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`editpersonalinfoform${_uid}`" class="ui submit teal button">Update</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <div ref="editconsultationmodal" class="ui hidden modal">
            <div class="content">
                <div :key="`editconsultationform_${editconsultationform_key}`">
                    <keep-alive>
                        <component ref="editconsultationform" :id="`editconsultationform${_uid}`" :is="editconsultationform" :formdata="editconsultationformdata" @submit="nextConsultation" @prev="prevConsultation"/>
                    </keep-alive>
                </div>
            </div>
            <div class="actions">
                <button type="button" class="ui alt teal left floated button" v-if="editconsultationform === 'medical-info-form'" @click="prevConsultation">Back</button>
                <button type="submit" :form="`editconsultationform${_uid}`" class="ui submit teal button">{{(editconsultationform === "medical-info-form")? "Update" : "Next"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <div ref="prescriptionmodal" class="ui hidden prescription modal">
            <div class="content">
                <prescription-form ref="prescriptionform" :id="`prescriptionform${_uid}`" :customer="selected_customer" :prescription="prescriptionformdata" @change:saving="prescriptionSavingListener" @save:prescription="prescriptionSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`prescriptionform${_uid}`" class="ui submit teal button" :class="{loading: saving_prescription}" :disabled="saving_prescription">Save</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";
import clonedeep from "lodash.clonedeep";
import merge from "lodash.merge";

import medicalinfoMessage from "@/i18n/consultation/medicalinfo";
import personalinfoMessage from "@/i18n/consultation/personalinfo";
import todayvisitMessage from "@/i18n/consultation/todayvisit";

import ConsultationForm from "@/views/consultation/ConsultationForm";
import PersonalInfoForm from "@/views/consultation/PersonalInfoForm";
import TodayVisitForm from "@/views/consultation/TodayVisitForm";
import MedicalInfoForm from "@/views/consultation/MedicalInfoForm";
import PrescriptionForm from "@/views/consultation/PrescriptionForm";
import PaginationMenu from "@/components/PaginationMenu";

export default {
    name: "customers",
    i18n: {
        sharedMessages: merge({}, medicalinfoMessage, personalinfoMessage, todayvisitMessage)
    },
    components: {
        PersonalInfoForm,
        TodayVisitForm,
        MedicalInfoForm,
        ConsultationForm,
        PrescriptionForm,
        PaginationMenu
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            editpersonalinfoform_key: (new Date()).getTime(),
            consultationform_key: (new Date()).getTime(),
            editconsultationform_key: (new Date()).getTime(),
            query: "",
            customers: [],
            selected_customer: null,
            editcustomerformdata: null,
            editconsultationformdata: {},
            editconsultationform: "today-visit-form",
            editconsultationforms: [
                "today-visit-form",
                "medical-info-form"
            ],
            prescriptionformdata: {},
            saving_prescription: false,
            sorting: {
                by: "created",
                order: "desc"
            },
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            opened_modals: [],
            history_popped: false,
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.sortbydropdown).dropdown();

        $(this.$refs.consultationmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "consultationmodal_opened");
                this.opened_modals.push("consultationmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "consultationmodal";
                });
            },
            onHidden: () => {
                this.consultationform_key = (new Date()).getTime();
            }
        });

        $(this.$refs.editcustomermodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "editcustomermodal_opened");
                this.opened_modals.push("editcustomermodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "editcustomermodal";
                });
            },
            onHidden: () => {
                this.editpersonalinfoform_key = (new Date()).getTime();
            }
        });

        $(this.$refs.editconsultationmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "editconsultationmodal_opened");
                this.opened_modals.push("editconsultationmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "editconsultationmodal";
                });
            },
            onHidden: () => {
                this.editconsultationform_key = (new Date()).getTime();
                this.editconsultationform = this.editconsultationforms[0];
                this.editconsultationformdata = {};
            }
        });

        $(this.$refs.aftersalesmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "aftersalesmodal_opened");
                this.opened_modals.push("aftersalesmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "aftersalesmodal";
                });
            }
        });
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order
            };

            if(this.query) {
                let params = {
                    name: this.query,
                    id_no: this.query,
                    contact_no: this.query
                };

                try {
                    let Tdob = this.$moment(this.query, "YYYY-MM-DD", true);
                    if(Tdob.isValid()) {
                        params.dob = this.query;
                    }
                } catch(error) {}

                search_params.search = JSON.stringify(params);
            }

            return search_params;
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        addConsultation() {
            $(this.$refs.consultationmodal).modal("show");
        },
        consultationSaved() {
            $(this.$refs.consultationmodal).modal("hide");
            this.search_();
        },
        consultationCancelled() {
            $(this.$refs.consultationmodal).modal("hide");
        },
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("customers", {
                params
            }).then((response) => {
                this.customers = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.customers.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list customers. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        resizeConsultationModal() {
            this.$nextTick(() => {
                $(this.$refs.consultationmodal).modal("refresh");
            });
        },
        changePage(page) {
            this.pagination.page = page;
        },
        editCustomer(customer) {
            this.editcustomerformdata = clonedeep(customer);
            $(this.$refs.editcustomermodal).modal("show");
        },
        updateCustomer({ profile }) {
            if(profile && profile.id) {
                this.$http.put(`customers/${profile.id}`, profile).then((response) => {
                    let customer = this.customers.find((customer) => {
                        return customer.id === profile.id;
                    });

                    if(customer) {
                        Object.assign(customer, response.data);
                    }

                    $(this.$refs.editcustomermodal).modal("hide");

                    this.$toasted.success("Customer detail updated successfully.", {
                        duration: 3000
                    });
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Unable to update customer detail.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.$refs.editcustomerform.submitting = false;
                });
            } else {
                this.$toasted.error("Unable to update customer detail.", {
                    duration: 3000
                });

                this.$refs.editcustomerform.submitting = false;
            }
        },
        editConsultation(customer) {
            let promise = null;
            if(customer.consultation) {
                promise = Promise.resolve({
                    data: customer.consultation
                });
            } else {
                promise = this.$http.get(`consultations/${customer.id}`);
            }

            promise.then(({ data }) => {
                if(!data.profile) {
                    data.profile = customer;
                }
                customer.consultation = data;

                this.editconsultationformdata = clonedeep(customer.consultation);
                $(this.$refs.editconsultationmodal).modal("show");
            });
        },
        nextConsultation(formdata) {
            Object.assign(this.editconsultationformdata, formdata);

            let form_index = this.editconsultationforms.indexOf(this.editconsultationform);
            if(form_index > -1) {
                if(form_index < this.editconsultationforms.length - 1) {
                    this.$refs.editconsultationform.submitting = false;
                    this.editconsultationform = this.editconsultationforms[form_index + 1];
                } else if(form_index === this.editconsultationforms.length - 1) {
                    let formdata = clonedeep(this.editconsultationformdata);
                    const profile = formdata.profile;
                    delete formdata.profile;

                    this.$http.put(`consultations/${this.editconsultationformdata.id}`, formdata).then((response) => {
                        let customer = this.customers.find((customer) => {
                            return customer.id === profile.id;
                        });

                        if(customer) {
                            Object.assign(customer.consultation, response.data);
                        }

                        $(this.$refs.editconsultationmodal).modal("hide");

                        this.$toasted.success("Consultation updated successfully.", {
                            duration: 3000
                        });
                    }).catch((error) => {
                        console.error(error);

                        this.$toasted.error("Unable to update consultation detail.", {
                            duration: 3000
                        });
                    }).finally(() => {
                        this.$refs.editconsultationform.submitting = false;
                    });
                }
            }
        },
        prevConsultation() {
            let form_index = this.editconsultationforms.indexOf(this.editconsultationform);
            if(form_index) {
                this.editconsultationform = this.editconsultationforms[form_index - 1];
            }
        },
        addPrescription(customer) {
            this.selected_customer = customer;
            this.prescriptionformdata = {
                customer_id: customer.id
            };

            $(this.$refs.prescriptionmodal).modal("show");
        },
        prescriptionSavingListener(saving) {
            this.saving_prescription = saving;
        },
        prescriptionSaved(prescription) {
            $(this.$refs.prescriptionmodal).modal("hide");

            this.selected_customer = null;
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.search !== _search_params.search) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customers.container {
    background: #fff;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>